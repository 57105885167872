export const ROUTE_PATH = {
  root: '/',
  login: '/login',
  dashBoard: '/dashboard',
  register: '/register',
  profile: '/profile',
  studentList: '/student-list',
  studentType: '/student-list-type',
  studentListType: '/student-list-type/:id',
  studentDetail: '/student-detail/:studentId',
  setting: '/setting',
};
export const ROUTE_API = {
  root: process.env.REACT_APP_API_URL,
  singIn: '/api/v1/auth/sign-in',
  registerStudent: '/api/v1/admin/attendees',
  eventList: '/api/v1/admin/events',
  getListStudent: '/api/v1/admin/attendees',
  studentDetail: '/api/v1/admin/attendees/:id',
  updateStudent: '/api/v1/admin/attendees/:id',
  deleteStudent: '/api/v1/admin/attendees/:id',
  addUser: '/api/v1/admin/users',
  userList: '/api/v1/admin/users',
  updateUser: '/api/v1/admin/users/:id',
  createEvent: '/api/v1/admin/events',
  deleteEvent: '/api/v1/admin/events/:id',
  deleteUser: '/api/v1/admin/users/:id',
  updateEvent: '/api/v1/admin/events/:id',
};
