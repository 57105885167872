import HttpUtil from '../utils/http-util';
import { ROUTE_API } from '../utils/route-util';

const EVENT_API = {
  listEvent: async () => {
    const res = await HttpUtil.get<IEventList.IEvent>(ROUTE_API.eventList);
    return res.data.data;
  },
  createEvent: async (data: IEventList.ICreateEvent) => {
    const res = await HttpUtil.post(ROUTE_API.createEvent, data);
    return res.data;
  },
  deleteEvent: async (id: string) => {
    const res = await HttpUtil.delete(ROUTE_API.deleteEvent.replace(':id', id));
    return res.data;
  },
  updateEvent: async (data: IEventList.ICreateEvent, id: string) => {
    const res = await HttpUtil.put(
      ROUTE_API.updateEvent.replace(':id', id),
      data,
    );
    return res.data;
  },
};

export { EVENT_API };
