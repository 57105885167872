import { PaletteColorOptions, createTheme } from '@mui/material/styles';

type TypeText = {
  primary: string;
  // success: string;
  // error: string;
  // info:string;
  blue: string;
  grey: string;
};
const primary: PaletteColorOptions = {
  main: '#027aff',
  light: '#e5a001',
  dark: '#007AFF',
};
const textColor: TypeText = {
  primary: '#000000',
  blue: '#5336E2',
  // success: "#229A16",
  // error: "#FF3A23",
  // info:'#202122'
  grey: '#6C7072',
};

const theme = createTheme({
  typography: {
    fontFamily:
      'Inter,Inter-Regular, Inter-Bold, Inter-Medium, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    fontSize: 14,
    h1: {
      fontSize: 40,
    },
    h2: {
      fontSize: 32,
    },
    h3: {
      fontSize: 28,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
    },
    h5: {
      fontSize: 18,
      fontWeight: 700,
    },
    h6: {
      fontSize: 16,
    },
    subtitle1: {
      fontSize: 12,
    },
    subtitle2: {
      fontSize: 14,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    button: {
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
    },
    overline: {
      fontSize: 12,
    },
  },
  palette: {
    primary,
    background: {
      paper: '#d7ab52',
      default: '#FFFFFF',
    },
    common: {
      white: '#FFFFFF',
    },
    success: {
      main: '#229A16',
      light: '#CFF6E4',
    },
    error: {
      main: '#EC221F',
      light: '#FFE5E5',
      dark: '#d64148',
    },
    warning: {
      main: '#A05E03',
      light: '#FFEFD7',
    },
    grey: {
      50: '#F2F4F5',
      100: '#6C7072',
      200: '#72777A',
      300: '#ABAFB1',
      400: '#d9d9d9',
      500: '#404041',
      600: '#404446',
      700: '#a0967b',
      800: '#202325',
      900: '#000000',
      A100: '#090A0A',
      A200: '#CDCFD0',
    },

    text: textColor,
    divider: 'rgba(145, 158, 171, 0.24)',
  },
  shape: {
    borderRadius: 4,
  },
});

theme.shadows[1] = '0px 5px 20px -4px #919EAB1F';
theme.shadows[2] = '0px 4px 12px 0px #00000026';
theme.shadows[3] = '0px 24px 48px 0px #919EAB33';
theme.shadows[4] = '0px 2px 2px 0px #0000001A';
theme.components = {
  MuiMenu: {
    defaultProps: {
      PaperProps: {
        sx: {
          borderRadius: '8px',
          boxShadow: '0px 4px 12px 0px #00000026',
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '.MuiPaper-root': {
          borderRadius: 2,
          background: theme.palette.common.white,
        },
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: ``,
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'capitalize',
        borderRadius: '12px',
        height: '48px',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: `${theme.palette.grey[50]} !important`,
          margin: '0 8px',
        },
        margin: '0 8px',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        borderColor: theme.palette.divider,
      },
    },
    defaultProps: {
      MenuProps: {
        PaperProps: {
          sx: {
            boxShadow: '0px 4px 12px 0px #00000026',
            borderRadius: '8px',
          },
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
        ':hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
};
export default theme;
