// import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';

import { useAuthContext } from '../context/AppContext';
import { ROUTE_PATH } from '../utils/route-util';

// import Toolbar from '@mui/material/Toolbar';

function Layout() {
  const { authState } = useAuthContext();
  if (!authState?.isLogIn) {
    return <Navigate to={ROUTE_PATH.login} replace />;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        background: 'linear-gradient(to bottom, #D7AB52, #FFFFFF)',
      }}
    >
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          width: '100%',
          // bgcolor: 'background.paper',
          background: 'linear-gradient(to bottom, #D7AB52, #FFFFFF)',
        }}
      >
        {/* <Toolbar /> */}
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;
