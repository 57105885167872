import Axios from 'axios';

import { IAuth } from '../context/AppContext';
import { getPersistedState } from './persist-util';
import { ROUTE_API } from './route-util';

const HttpUtil = Axios.create({
  baseURL: ROUTE_API.root,
});

HttpUtil.interceptors.request.use(function (config) {
  const persistedState: IAuth =
    getPersistedState(process.env.REACT_APP_PERSIST_AUTH) || {};
  config.headers.Authorization = persistedState?.accessToken
    ? `Bearer ${persistedState?.accessToken}`
    : '';
  config.headers.Accept = '*/*';

  return config;
});

HttpUtil.interceptors.response.use(
  async function onSuccess(res) {
    if (res?.data?.status < 400) {
      return res;
    } else {
      return Promise.reject(res.data);
    }
  },
  async function onError(err) {
    const res = err.response;
    console.log('Error Response:', res?.data?.status);
    const persistedState: IAuth =
      getPersistedState(process.env.REACT_APP_PERSIST_AUTH) || {};
    if (res?.data?.status === 401 && persistedState) {
      if (!persistedState) {
        return Promise.reject(res.data);
      }
    }
    localStorage.clear();
    alert(`Unauthorized ${res?.data?.status}`);
    window.location.reload();
    return Promise.reject(res.data);
  },
);

export default HttpUtil;
