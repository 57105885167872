import { useRequest } from 'ahooks';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { EVENT_API } from '../api/Event';
import { getPersistedState, persistState } from '../utils/persist-util';

export interface IAuth extends IAuth.Data {
  isLogIn?: boolean;
  studentType?: string;
  studentTypeId?: string;
}

interface AuthContextProps {
  authState: IAuth;
  setAuthState: React.Dispatch<React.SetStateAction<IAuth>>;
  setLogInStatus: (status: boolean) => void;
  dataListEvent: IEventList.Daum[] | undefined;
  refreshList: () => void;
  errorListEvent: Error | undefined;
  loadingList: boolean;
}

export const AuthContext = createContext<AuthContextProps | null>(null);
export const AuthWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const local: IAuth = getPersistedState(process.env.REACT_APP_PERSIST_AUTH);
  const [authState, setAuthState] = useState<IAuth>(local);
  const initMount = useRef(true);
  useEffect(() => {
    if (!initMount.current) {
      if (!authState?.isLogIn) {
        localStorage.removeItem(process.env.REACT_APP_PERSIST_AUTH || '');
      } else {
        persistState(process.env.REACT_APP_PERSIST_AUTH || '', authState);
      }
    } else initMount.current = false;
  }, [authState]);

  const setLogInStatus = (status: boolean) => {
    setAuthState((prevAuthState: IAuth) => ({
      ...prevAuthState,
      isLogIn: status,
    }));
  };
  const {
    loading: loadingList,
    data: dataListEvent,
    refresh: refreshList,
    error: errorListEvent,
  } = useRequest(EVENT_API.listEvent, {
    onSuccess: (data) => {
      // console.log('list event', data);
    },
    ready: authState?.accessToken !== undefined,
  });
  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        setLogInStatus,
        dataListEvent,
        refreshList,
        errorListEvent,
        loadingList,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('Error Use Context');
  }
  return context;
};
