import { RouterProvider } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';

import './App.css';
import { AuthWrapper } from './context/AppContext';
import { router } from './routes';
import theme from './themes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthWrapper>
        <RouterProvider router={router} />
      </AuthWrapper>
    </ThemeProvider>
  );
}
export default App;
