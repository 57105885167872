import { Suspense, lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import Layout from '../layouts';
import { ROUTE_PATH } from '../utils/route-util';

const Login = lazy(() => import('../pages/authPage'));
const DashBoard = lazy(() => import('../pages/dashBoardPage'));
const Register = lazy(() => import('../pages/registerPage'));
const StudentType = lazy(() => import('../pages/studentListTypePage'));
const StudentListType = lazy(
  () => import('../pages/studentListTypePage/studentListType'),
);
const StudentDetail = lazy(
  () => import('../pages/studentListTypePage/studentListType/studentDetail'),
);
const Setting = lazy(() => import('../pages/setting'));
const router = createBrowserRouter(
  [
    {
      path: ROUTE_PATH.login,
      element: (
        <Suspense>
          <Login />
        </Suspense>
      ),
    },
    {
      path: ROUTE_PATH.root,
      element: <Layout />,
      errorElement: <Navigate to={ROUTE_PATH.root} />,
      children: [
        {
          path: ROUTE_PATH.login,
          element: (
            <Suspense>
              <Login />
            </Suspense>
          ),
        },
        {
          path: ROUTE_PATH.root,
          element: (
            <Suspense>
              <DashBoard />
            </Suspense>
          ),
        },
        {
          path: ROUTE_PATH.setting,
          element: (
            <Suspense>
              <Setting />
            </Suspense>
          ),
        },
        {
          path: ROUTE_PATH.register,
          element: (
            <Suspense>
              <Register />
            </Suspense>
          ),
        },

        {
          path: ROUTE_PATH.studentType,
          element: (
            <Suspense>
              <StudentType />
            </Suspense>
          ),
        },
        {
          path: ROUTE_PATH.studentDetail,
          element: (
            <Suspense>
              <StudentDetail />
            </Suspense>
          ),
        },
        {
          path: ROUTE_PATH.studentListType,
          element: (
            <Suspense>
              <StudentListType />
            </Suspense>
          ),
        },
        {
          path: ROUTE_PATH.root,
          element: <Navigate to={ROUTE_PATH.root} replace />,
        },
      ],
    },
  ],
  { basename: process.env.REACT_APP_BASE_PATH },
);

export { router };
